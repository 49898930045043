import styled, { css } from "styled-components";
import { Container } from "styles/components/Container.styled";

// ** Styles Import
import { breakpoints } from "styles/grid";

// ** Constants
import { topHeaderHeight, topNavMenuHeight } from "styles/declares";

// #####################################################

export const StyledMainSection = styled(Container)`
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;
	padding: ${topHeaderHeight.desktop + 10}px 0px 0;
	position: relative;

	@media (max-width: ${breakpoints.desktop - 1}px) {
		padding-top: ${topHeaderHeight.tablet + 10}px;
	}
	@media (max-width: ${breakpoints.tablet - 1}px) {
		padding-top: ${topHeaderHeight.mobile + 10}px;
	}

	${({ showTopMenu }) =>
		showTopMenu &&
		css`
			padding-bottom: 0;

			@media (max-width: ${breakpoints.desktop - 1}px) {
				padding: ${topHeaderHeight.tablet + 10 + topNavMenuHeight}px 0px
					0;
				padding-top: ${topHeaderHeight.tablet +
				10 +
				topNavMenuHeight}px;
			}
			@media (max-width: ${breakpoints.tablet - 1}px) {
				padding: ${topHeaderHeight.mobile + 10 + topNavMenuHeight}px 0px
					0;
				padding-top: ${topHeaderHeight.mobile +
				10 +
				topNavMenuHeight}px;
			}
		`}
`;

// #####################################################
