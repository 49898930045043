// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** React Router
import { useLocation, useHistory, matchPath } from "react-router-dom";

// ##################################

const useModal = ({ onModalOpen = null, onModalClose = null } = {}) => {
	// ** Zmienne stanu komponentu
	const [showModal, setShowModal] = useState(false);
	const [showComments, setShowComments] = useState(false);
	const [userLogin, setUserLogin] = useState("");
	const [mediaType, setMediaType] = useState("");
	const [contentId, setContentId] = useState("");
	const [albumId, setAlbumId] = useState("");

	// ** Hooki
	const location = useLocation();
	const history = useHistory();

	// ** Funkcja zamyka okno modala
	const closeModal = (noScroll = false) => {
		if (typeof onModalClose === "function") {
			onModalClose(noScroll);
		}

		if (!history.location.hash) {
			return;
		}

		history.push({
			pathname: location.pathname,
			search: location.search,
			state: "isModal",
		});
	};

	const parseLocationLink = useCallback(() => {
		const {
			params: { login, type, contentId, album },
		} = matchPath(location.hash.slice(1), {
			path: "users/:login/:type/:contentId/:album?",
		});
		setUserLogin(login);
		setMediaType(type);
		setContentId(contentId);
		setAlbumId(album);
	}, [location.hash]);

	// ##################################

	useEffect(() => {
		const shouldShowModal = matchPath(location.hash.slice(1), {
			path: "users/:login/:type/:contentId/:album?",
		});
		setShowModal(shouldShowModal);
		if (shouldShowModal) {
			parseLocationLink();
		}
	}, [location.hash]);

	// ** Efekt w momencie otwarcia modala wywołuje funkcję 'onModalOpen'
	useEffect(() => {
		if (typeof onModalClose === "function") onModalOpen();
	}, []);

	// ##################################

	return {
		showModal,
		closeModal,
		showComments,
		setShowComments,
		parsedLink: { userLogin, mediaType, contentId, albumId },
	};
};

export default useModal;
