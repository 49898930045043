// ** Third Party Components
import styled, { css } from "styled-components";
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledSpace = styled.div`
	position: relative;
	width: 100%;
	margin: 0 auto;

	${({ spaceHeight }) =>
		spaceHeight &&
		css`
			height: ${spaceHeight};
		`}
	background-color: transparent;

	@media (min-width: ${breakpoints.tablet}px) {
		width: ${breakpoints.tablet - 1}px;
	}

	@media (min-width: ${breakpoints.desktop}px) {
		width: ${breakpoints.desktop - 1}px;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 200px;
			width: 3px;
			height: 100%;
			background-color: transparent;
		}
	}

	@media (min-width: ${breakpoints.giant}px) {
		width: ${breakpoints.giant - 1}px;
	}

	@media (min-width: ${breakpoints.veryGiant}px) {
		width: ${breakpoints.veryGiant - 1}px;
	}
`;

// #####################################################
