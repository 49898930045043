import styled, { css } from "styled-components";

// #####################################################

// ** Design Tokens

const mainWrapperDesignTokens = css`
	--dz-comp-wrapper-background-color: var(--dz-ref-color-gray100);
`;

// #####################################################

export const StyledMainWrapper = styled.div`
	${mainWrapperDesignTokens}

	z-index: 1;
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	padding: 0;
	margin: 0 auto;
	background-color: var(--dz-comp-wrapper-background-color);
	overflow: hidden;
`;

// #####################################################
