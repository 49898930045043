// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import { StyledNavigationMenuListItemLink } from "./NavigationMenuListItemLink.styled";
import { StyledNavigationMenuListItemIcon } from "./NavigationMenuListItemIcon.styled";

// #####################################################

export const StyledNavigationMenuListItem = styled("li").attrs(() => ({
	role: "menuitem",
}))`
	display: block;
	background-color: transparent;

	${StyledNavigationMenuListItemLink} {
		color: ${({ theme: { colors } }) => colors.black};
		&:visited {
			color: ${({ theme: { colors } }) => colors.black};
		}
	}

	&:hover,
	&.active {
		${StyledNavigationMenuListItemLink} {
			color: ${({ theme: { colors, isDarkMode } }) =>
				isDarkMode
					? colors.primaryLight
					: "var(--dz-ref-color-primary400)"};
		}

		${StyledNavigationMenuListItemIcon} {
			svg {
				color: ${({ theme: { colors, isDarkMode } }) =>
					isDarkMode
						? colors.primaryLight
						: "var(--dz-ref-color-primary400)"};
			}
		}
	}
`;
