// ** React
import { forwardRef } from "react";

// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import { CardWrapper } from "components/layout/Card/CardWrapper";

// #####################################################

export const StyledSideMenuCard = styled(
	forwardRef(({ extended, isAuth, ...rest }, ref) => (
		<CardWrapper ref={ref} {...rest} />
	))
)`
	position: fixed;
	top: 71px;
	height: ${({ isAuth }) =>
		isAuth ? `calc(100% - 81px)` : `calc(100% - 81px - 68px)`};
	width: ${({ extended }) => (extended ? `250px` : `200px`)};
	margin-right: var(--size-grid);

	border-radius: var(--dz-sys-card-border-radius);
`;

// #####################################################
