/* eslint-disable react/display-name */
import React from "react";
import { generatePath } from "react-router-dom";
import { window } from "ssr-window";
import RedirectWithStatus from "components/other/RedirectWithStatus";

export default (routes, pathParams) => {
	return [
		...routes,
		routes.length > 0
			? {
					render: (props) => {
						const to =
							(routes[0].link ||
								generatePath(routes[0].path, pathParams)) +
							window.location.hash;
						return <RedirectWithStatus to={to} {...props} />;
					},
			  }
			: false,
	].filter((route) => route !== false);
};
