import { useEffect, useRef } from "react";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import { throttle } from "throttle-debounce";
import useMobileDetect from "hooks/useMobileDetectHook";

interface ScrollPositionMap {
	[key: string]: number | null;
}

const ScrollPositionSaver = ({
	mainWrapperRef,
}: {
	mainWrapperRef: React.MutableRefObject<HTMLElement>;
}) => {
	const { state } = useLocation();
	const {
		action,
		replace,
		location: { pathname, search, hash },
	} = useHistory();
	const mobileDetect = useMobileDetect();
	const isIos = mobileDetect.isIos();

	const positionScrollY = useRef(0);
	const path = `${pathname}${search}`;

	const scrollSaveDisabled =
		typeof sessionStorage === "undefined" ||
		sessionStorage === null ||
		typeof window === "undefined" ||
		typeof ResizeObserver !== "function" ||
		typeof ResizeObserver === "undefined" ||
		!mainWrapperRef?.current ||
		hash;

	let savedScrollsPositions: ScrollPositionMap = {};
	let currentPageScrollPosition: number | null = null;

	if (!scrollSaveDisabled) {
		const fromSessionStorage = sessionStorage.getItem(
			"savedScrollsPositionsFromDZ"
		);
		savedScrollsPositions = fromSessionStorage
			? JSON.parse(fromSessionStorage)
			: {};

		currentPageScrollPosition =
			typeof savedScrollsPositions[path] === "number"
				? savedScrollsPositions[path]
				: null;
	}

	const saveScrollPositionToSessionStorage = () => {
		if (scrollSaveDisabled) {
			return;
		}

		savedScrollsPositions[path] = positionScrollY.current;

		// positionScrollY.current = 0; - do sprawdzenia czy czegoś nie zepsuło zakomentowanie.
		const keys = Object.keys(savedScrollsPositions);
		if (keys.length >= 11) {
			delete savedScrollsPositions[keys[0]];
		}

		sessionStorage.setItem(
			"savedScrollsPositionsFromDZ",
			JSON.stringify(savedScrollsPositions)
		);
	};

	useEffect(() => {
		if (scrollSaveDisabled) {
			return;
		}
		if (state === "afterLogin") {
			replace({ ...location, state: null });
			return;
		}

		const resizeObserver = new ResizeObserver(() => {
			const maxHeightScrollY =
				Math.max(
					document.documentElement.scrollHeight,
					document.body.scrollHeight
				) - window.innerHeight;

			if (
				action === "POP" &&
				maxHeightScrollY > 0 &&
				window.scrollY !== currentPageScrollPosition &&
				currentPageScrollPosition !== null
			) {
				window.scrollTo(0, currentPageScrollPosition);
				if (currentPageScrollPosition === 0) {
					resizeObserver.disconnect();
				}
			}
		});

		const removeScrollPositionFromSessionStorage = setTimeout(() => {
			if (
				action === "POP" &&
				Object.keys(savedScrollsPositions).length > 0 &&
				typeof savedScrollsPositions[path] === "number"
			) {
				const filteredPositions = Object.keys(
					savedScrollsPositions
				).reduce((newObj: ScrollPositionMap, key) => {
					if (key !== path) {
						newObj[key] = savedScrollsPositions[key];
					}
					return newObj;
				}, {});
				if (Object.keys(savedScrollsPositions).includes(path)) {
					delete savedScrollsPositions[path];
				}

				if (currentPageScrollPosition !== null) {
					currentPageScrollPosition = null;
					resizeObserver.disconnect();
				}

				sessionStorage.setItem(
					"savedScrollsPositionsFromDZ",
					JSON.stringify(filteredPositions)
				);
			}
		}, 1000);

		resizeObserver.observe(mainWrapperRef.current);

		const handleScroll = throttle(300, () => {
			positionScrollY.current = window.scrollY;
		});
		document.addEventListener("scroll", handleScroll);

		const handleBeforeUnload = () => {
			sessionStorage.clear();
		};

		window.addEventListener(
			isIos ? "pagehide" : "beforeunload",
			handleBeforeUnload
		);
		return () => {
			clearTimeout(removeScrollPositionFromSessionStorage);
			resizeObserver.disconnect();
			document.removeEventListener("scroll", handleScroll);
			window.removeEventListener(
				isIos ? "pagehide" : "beforeunload",
				handleBeforeUnload
			);
		};
	}, [
		path,
		action,
		hash,
		mainWrapperRef,
		currentPageScrollPosition,
		mainWrapperRef.current?.clientHeight,
		scrollSaveDisabled,
		state,
	]);

	return (
		<Prompt
			message={() => {
				saveScrollPositionToSessionStorage();
				// Prompt expects a function that returns a string or boolean as a message.
				return true;
			}}
		/>
	);
};

export default ScrollPositionSaver;
