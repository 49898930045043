import { useCallback } from "react";
import { matchPath } from "react-router-dom";
import { NavLink as NavLinkStandard, useLocation } from "react-router-dom";
import { createLocation } from "history";
import Block from "components/layout/Block";

const resolveToLocation = (to, currentLocation) =>
	typeof to === "function" ? to(currentLocation) : to;

const normalizeToLocation = (to, currentLocation) => {
	return typeof to === "string"
		? createLocation(to, null, null, currentLocation)
		: to;
};

function joinClassnames(...classnames) {
	return classnames.filter((i) => i).join(" ");
}

const NavItemLink = ({
	activeClassName = "active",
	classNameProp,
	container: containerComponent,
	to,
	exact,
	sensitive,
	strict,
	isActive: isActiveProp,
	disabled,
	scroll = true,
	onClick,
	userpanelMenu,
	noTo = false,
	...props
}) => {
	const currentLocation = useLocation();

	const toLocation = normalizeToLocation(
		resolveToLocation(to, currentLocation),
		currentLocation
	);

	const path = toLocation?.pathname ?? "";
	const escapedPath =
		path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");

	let match = escapedPath
		? matchPath(currentLocation?.pathname, {
				path: escapedPath,
				exact,
				sensitive,
				strict,
		  })
		: null;

	if (currentLocation?.pathname === "/userpanel/gallery/photos/avatar") {
		if (path === "/userpanel/data") {
			match = true;
		} else {
			match = false;
		}
	}

	if (currentLocation?.pathname === "/userpanel/links") {
		if (path === "/userpanel/data") {
			match = true;
		} else {
			match = false;
		}
	}

	const isActive =
		to !== "/"
			? !!(isActiveProp ? isActiveProp(match, currentLocation) : match)
			: false;

	const handleClick = useCallback(
		(event) => {
			if (scroll) {
				if (scroll === true) {
					window.scrollTo(0, 0);
				} else if (scroll && scroll.current) {
					const position =
						scroll.current.getBoundingClientRect().top +
						window.pageYOffset -
						62; // 61 px - wysokość topbara + 1px
					window.scrollTo(0, position);
				}
			}
			if (onClick) {
				onClick(event);
			}
		},
		[onClick, scroll]
	);

	if (containerComponent) {
		const NavLinkContainer = containerComponent;
		const containerClassName = isActive
			? joinClassnames(classNameProp, activeClassName)
			: classNameProp;
		const containerProps = {
			// "aria-current": (isActive && ariaCurrent) || null,
			isActive: isActiveProp,
			className: containerClassName,
			userpanelMenu,
		};
		return (
			<NavLinkContainer {...containerProps}>
				{disabled || noTo ? (
					<Block
						exact={exact}
						sensitive={sensitive}
						strict={strict}
						isActive={isActiveProp}
						activeClassName={activeClassName}
						style={
							disabled
								? {
										cursor: "not-allowed",
										color: "var(--color-grey)",
								  }
								: noTo
								? { cursor: "pointer" }
								: {}
						}
						suppressHydrationWarning
						onClick={(event) => (noTo ? handleClick(event) : null)}
						{...props}
					/>
				) : (
					<NavLinkStandard
						to={disabled ? "#" : to ? to : "#"}
						exact={exact}
						sensitive={sensitive}
						strict={strict}
						isActive={isActiveProp}
						activeClassName={activeClassName}
						style={
							disabled
								? {
										cursor: "not-allowed",
										color: "var(--color-grey)",
								  }
								: {}
						}
						onClick={(event) => handleClick(event)}
						suppressHydrationWarning
						{...props}
					/>
				)}
			</NavLinkContainer>
		);
	}
	return (
		<NavLinkStandard
			to={disabled ? "#" : to ? to : "#"}
			exact={exact}
			sensitive={sensitive}
			strict={strict}
			isActive={isActiveProp}
			activeClassName={activeClassName}
			style={disabled ? { cursor: "not-allowed" } : {}}
			onClick={(event) => handleClick(event)}
			{...props}
		/>
	);
};

NavItemLink.displayName = "NavItemLink";

export default NavItemLink;
