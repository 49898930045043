import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

const RedirectWithStatus = ({ staticContext, from, to, status }) => {
	if (staticContext) {
		staticContext.url = to;
		staticContext.status = status;
	}
	return <Redirect from={from} to={to} />;
};

RedirectWithStatus.defaultProps = {
	status: 307,
};

RedirectWithStatus.propTypes = {
	status: PropTypes.number,
};

export default RedirectWithStatus;
