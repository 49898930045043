// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import NavItemLink from "components/base/NavItemLink";

// #####################################################

export const StyledNavigationMenuListItemLink = styled(NavItemLink)`
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;
	padding: 0.5rem;

	&:focus {
		z-index: 2;
	}
`;

// #####################################################
