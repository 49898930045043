// ** Third Party Components
import styled, { css } from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledMainContent = styled("div")`
	flex: 1;
	max-width: 100%;

	@media (min-width: ${breakpoints.desktop}px) {
		margin-left: calc(200px + var(--size-grid));

		${({ isUserpanel }) =>
			isUserpanel &&
			css`
				margin-left: calc(250px + var(--size-grid));
			`}
	}
`;

// #####################################################
