// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledSideFixed = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 0px;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: auto;
`;

// #####################################################
