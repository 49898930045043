// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Icon from "components/global/Icon";

// #####################################################

export const StyledNavigationMenuListItemIcon = styled(Icon)`
	font-size: 1.2rem;
	min-width: 2rem;
	display: inline-block;

	svg {
		width: 21px;
	}
`;

// #####################################################
