// ** Third Party Components
import styled, { css } from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: var(--dz-sys-card-background-color);
	max-width: calc(100vw);
	min-width: 200px;
	border-radius: var(--dz-sys-card-border-radius);
	box-shadow: var(--dz-sys-card-shadow);

	@media (max-width: ${breakpoints.phone - 1}px) {
		border-radius: 0;
	}

	${({ $fullHeight }) =>
		$fullHeight &&
		css`
			height: 100%;
		`}

	// includ in masonry
	${({ $isMasonry }) =>
		$isMasonry &&
		css`
			border-radius: var(--dz-sys-card-border-radius);
			margin: var(--size-grid) 0;
			@media (max-width: ${breakpoints.phone - 1}px) {
				border-radius: 0;
			}
		`}

	${({ $isMasonryAndIncludesWrapper }) =>
		$isMasonryAndIncludesWrapper &&
		css`
			box-shadow: none;
		`}
`;

// #####################################################
