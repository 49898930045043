// ** React Imports
import { useContext, useEffect, useMemo, useRef } from "react";
import { useLocation, useHistory, matchPath } from "react-router-dom";
import { renderRoutes } from "react-router-config";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";

// ** Custom Components
import Block from "components/layout/Block";
import Text from "components/typography/Text";
import ScrollPositionSaver from "components/other/ScrollPositionSaver";
import ButtonFloating from "components/layout/Button/ButtonFloating";

// ** Custom Hooks
import useModal from "hooks/useModal";

// ** Services
import { mainPageNavItemsFunc } from "../services/mainPageNavItems";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";
import containerRoutes from "helpers/containerRoutes";

// ** Contexts
import authContext from "contexts/authContext";
import deviceTypeContext from "contexts/deviceTypeContext";
import { MainProvider } from "../MainContext";

// ** Styled Components
import { StyledMainSection, StyledMainWrapper, StyledSpace } from "./styles";

// ** Lazy Loading Imports
const SideMenu = loadable(() => import("./SideMenu"));
const Header = loadable(() => import("components/global/Header/Header"));
const MainContent = loadable(() => import("./MainContent"));
const BottomFloatingBar = loadable(
	() => import("components/layout/BottomFloatingBar"),
	{
		ssr: false,
	}
);
const Modal = loadable(() => import("containers/Modal/Modal"), {
	ssr: false,
});

// #####################################################

const MainWrapper = ({ routes }) => {
	const { t } = useTranslation(["common", "pages"]);

	const location = useLocation();
	const history = useHistory();

	const isAuth = useContext(authContext);

	const userFrozen = useSelector((state) => state.global.user.frozen);
	const statistics = useSelector((state) => state.global.statistics);
	const language = useSelector((state) => state.global.language);

	const statisticsMemonized = useMemo(() => {
		return statistics;
	}, [statistics.online]);

	const { isDesktop, isPhone } = useContext(deviceTypeContext);

	const { showModal } = useModal();

	const finalRoutes = userFrozen
		? containerRoutes(routes.filter(({ meta }) => meta?.suspended))
		: routes;

	const matchPhoneVerificationPath =
		matchPath(location.pathname, {
			path: "/registration-code/:login?/:id?/:hash?",
			exact: true,
			sensitive: true,
			strict: false,
		}) ||
		matchPath(location.pathname, {
			path: "/registration-phone/:login?/:id?/:hash?",
			exact: true,
			sensitive: true,
			strict: false,
		});

	const mainPageNavItems = useMemo(() => {
		return mainPageNavItemsFunc(t, statisticsMemonized).map((item) => {
			if (item.key === "users") {
				item.content = () => (
					<Block style={{ lineHeight: "0.9rem" }}>
						<Block>{item.label}</Block>
						<Block>
							<Text small>
								Online{" "}
								<Text bold>
									{pseudoFormat(statisticsMemonized.online)}
								</Text>
							</Text>
						</Block>
					</Block>
				);
			}

			return item;
		});
	}, [statisticsMemonized.online, isAuth, language]);

	const mainWrapperRef = useRef(null);
	const headerRef = useRef(null);

	const isConversation = matchPath(location.pathname, {
		path: "/userpanel/messages/show/:id",
		exact: true,
	});

	useEffect(() => {
		if (
			!isConversation &&
			mainWrapperRef.current &&
			isPhone &&
			mainWrapperRef.current?.style?.position == "fixed" &&
			mainWrapperRef.current?.style?.height == "100%" &&
			mainWrapperRef.current?.style?.minHeight == "auto"
		) {
			mainWrapperRef.current.style.position = null;
			mainWrapperRef.current.style.height = null;
			mainWrapperRef.current.style.minHeight = null;
		}
	}, [isConversation]);

	useEffect(() => {
		if (isAuth) {
			Modal.preload();
		}
	}, [isAuth]);

	// #####################################################

	return (
		<>
			<ScrollPositionSaver
				/* WARNING: Don't remove the "mainWrapperRef" prop, because component stop working. 
				 Be careful when moving the component, it could make it stop working. */
				mainWrapperRef={mainWrapperRef}
			/>
			<Header
				ref={headerRef}
				id="header"
				locationPathname={location.pathname}
				mainPageNavItems={mainPageNavItems}
			/>
			{isAuth && showModal && (
				<Modal headerRef={headerRef} mainWrapperRef={mainWrapperRef} />
			)}
			<StyledMainWrapper ref={mainWrapperRef}>
				<StyledMainSection id="main" as="section" showTopMenu={true}>
					{isDesktop ? (
						<SideMenu
							mainPageNavItems={mainPageNavItems}
							statistics={statisticsMemonized}
						/>
					) : null}
					<MainContent locationPathname={location.pathname}>
						<MainProvider value={{ mainWrapperRef }}>
							{renderRoutes(finalRoutes)}
						</MainProvider>

						{isAuth && <BottomFloatingBar />}
					</MainContent>
				</StyledMainSection>
				{!isAuth && !matchPhoneVerificationPath && (
					<>
						<StyledSpace
							spaceHeight={"68px"}
							isDesktop={isDesktop}
						/>
						<ButtonFloating
							onClick={() => {
								history.push({
									pathname: "/",
									state: "register",
								});
								window.scrollTo(0, 0);
							}}
						>
							{t("landing:registerForFree")}
						</ButtonFloating>
					</>
				)}
			</StyledMainWrapper>
		</>
	);
};

// #####################################################

export default MainWrapper;

// #####################################################
