// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Styles Imports
import { ButtonPrimary } from "components/layout/Button/ButtonPrimary";
import { StyledFallbackContainer } from "../styles/FallbackContainer.styled";
import { StyledMessageContainer } from "../styles/MessageContainer.styled";

// ** Icons Imports
import FallbackLoader from "components/icons/FallbackLoader";

// #####################################################

const FallbackContent = ({ missingChunkType, reloadPage }) => {
	const { t } = useTranslation();

	return (
		<StyledFallbackContainer>
			<StyledMessageContainer>
				<p>
					{missingChunkType
						? t("fallback.update.message")
						: t("errors.commonErrorCatch")}
				</p>
				<FallbackLoader />
				<p>{t("fallback.update.reload")}</p>
				<ButtonPrimary
					my={4}
					label={t("errors.refresh")}
					type="button"
					onClick={reloadPage}
				/>
			</StyledMessageContainer>
		</StyledFallbackContainer>
	);
};

// #####################################################

export default FallbackContent;

// #####################################################
