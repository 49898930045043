// ** Routes
import { routesArray } from "containers";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";

// #####################################################

export const mainPageNavItemsFunc = (t, statistics) =>
	routesArray
		.filter(({ meta }) => meta.mainNavItem)
		.sort((a, b) => {
			if (a.meta.mainNavIndex > b.meta.mainNavIndex) return 1;
			if (a.meta.mainNavIndex < b.meta.mainNavIndex) return -1;
			return 0;
		})
		.map((route) => {
			let content = "";
			const label = t(`pages:${route.meta.transKey || route.key}`);
			content += label;
			if (
				route.meta.statisticsKey &&
				statistics?.[route.meta.statisticsKey]
			) {
				content += ` (${pseudoFormat(
					statistics?.[route.meta.statisticsKey] || 0
				)})`;
			}
			const finalRoute = {
				key: route.key,
				content,
				label,
				link: {
					to: route.link,
					query: route?.meta?.initialQuery || null,
				},
				path: route.path,
				statisticsKey: route.meta.statisticsKey || null,
				icon: route.meta.icon || null,
				iconActive: route.meta.iconActive || null,
				iconInactive: route.meta.iconInactive || null,
			};

			return finalRoute;
		});

// #####################################################
